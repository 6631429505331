<template>
  <div
    :class="
      'col-xl-' +
      prodotto.colonne +
      ' col-md-' +
      prodotto.colonne +
      ' col-sm-12'
    "
  >
    <loading :active="isLoading" :is-full-page="false"></loading>
    <card
      :gradient="index % 2 === 0 ? 'info' : 'secondary'"
      class="card-pricing shadow-lg rounded border-0 text-center mb-4 p-3"
      :style="{ minHeight: '96%' }"
      header-classes="bg-transparent"
      :header-styles="{
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100px',
        backgroundImage: 'url(' + prodotto.urlImage + ')',
      }"
      body-classes="px-lg-7"
      footer-classes="bg-transparent"
    >
      <template v-slot:header>
        <h4
          :class="
            index % 2 === 0
              ? 'text-uppercase ls-1 text-white py-3 mb-0'
              : 'text-uppercase ls-1 text-black py-3 mb-0'
          "
        >
          {{ prodotto.descrizione }}
        </h4>
      </template>
      <div class="row" v-if="assistenza">
        <div class="col-md-12">
          <h2 class="text-black mb-0">
            Congraturazioni!!!<br />
            Hai acquistato Assistenza Senza Sorprese<br />
            Ora puoi avvalerti dei nostri servizi.<br /><br />
            <base-button
              type="primary"
              :class="index % 2 === 0 ? 'text-white' : 'text-black'"
              @click="goToOpenAssistenza()"
              >CLICCA QUI PER APRIRE UNA RICHIESTA AL TUO AGENTE</base-button
            >
          </h2>
          <br />
          <base-alert type="warning"
            >Il tuo abbonamento si rinnoverà il
            {{ formatDate(assistenza.data_scadenza) }}</base-alert
          >

          <base-alert type="success" v-if="numOpen > 0"
            ><div class="mb-3">
              Hai {{ numOpen }} richieste di assistenza aperte
            </div>
            <base-button
              class="mt-3"
              :block="true"
              size="sm"
              type="primary"
              @click="goToStoricoRichieste()"
              >Clicca qui per controllarne lo stato</base-button
            >
          </base-alert>
        </div>
      </div>
      <div v-else>
        <span
          :class="
            index % 2 === 0 ? 'text-white text-left' : 'text-black text-left'
          "
          v-html="prodotto.descrizioneLunga"
        ></span>
      </div>
      <template v-if="assistenza" v-slot:footer>
        <div class="row">
          <div class="col-md-12 col-sm-12 mb-2">
            <base-button
              :block="true"
              size="sm"
              type="primary"
              @click="goToStoricoCar()"
              >Storico Controllo Annuale Rischi</base-button
            >
            <base-button
              :block="true"
              size="sm"
              type="primary"
              @click="openContratto()"
              >Visualizza Contratto</base-button
            >
            <base-button
              :block="true"
              size="sm"
              type="primary"
              @click="goToStoricoPagamenti()"
              v-if="prodotto.enablefatt"
              >Storico Pagamenti</base-button
            >
            <base-button
              :block="true"
              size="sm"
              type="primary"
              @click="goToPortalePagamenti()"
              v-if="prodotto.enablefatt"
              >Portale pagamenti</base-button
            >
            <base-button
              :block="true"
              size="sm"
              type="primary"
              @click="goToStoricoRichieste()"
              >Storico richieste di assistenza</base-button
            >
            <base-button
              :block="true"
              size="sm"
              type="primary"
              @click="goToAgente()"
              >Il tuo Agente</base-button
            >
          </div>
        </div>
      </template>
      <template v-else v-slot:footer>
        <a
          @click="goToAgente"
          style="cursor: pointer"
          :class="index % 2 === 0 ? 'text-white' : 'text-black'"
          >Contattaci</a
        >
      </template>
    </card>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { onMounted, ref } from "vue";
import apiCall from "@/utils/api";
import { formatDate, toCurrency } from "@/filters/formatDate";
import Loading from "vue-loading-overlay";
import BaseAlert from "@/components/BaseAlert";
import { runToast } from "@/util/notif";
import { useStore } from "vuex";

export default {
  name: "AssistenzaSenzaSorpreseComponent",
  props: {
    prodotto: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  components: {
    BaseAlert,
    Loading,
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const isLoading = ref(false);
    const assistenza = ref(null);
    const numOpen = ref(0);

    onMounted(async () => {
      await loadData();
    });

    const loadData = async function () {
      isLoading.value = true;
      let resp = await apiCall({
        url: "/private/assistenza-senza-sorprese/info",
        data: {},
        method: "GET",
      });
      if (resp.err) {
        assistenza.value = null;
      } else {
        assistenza.value = resp;
      }

      let respCount = await apiCall({
        url: "/private/assistenza-senza-sorprese/richieste-aperte",
        data: {},
        method: "GET",
      });

      numOpen.value = respCount;
      isLoading.value = false;
    };

    store.watch(
      (state) => state.user.prodotti,
      async () => {
        await loadData();
      }
    );

    const openDiagnosi = async function () {
      try {
        let resp = await apiCall({
          url:
            "/private/diagnosi/getlink/" +
            assistenza.value.idlogcontattodiagnosi,
          method: "GET",
        });
        window.open(resp.url, "_blank");
      } catch (e) {
        runToast("Errore download file", "top-center", "danger");
      }
    };

    const openContratto = async function () {
      try {
        let resp = await apiCall({
          url: "/private/assistenza-senza-sorprese/contratto",
          method: "GET",
        });
        window.open(resp.url, "_blank");
      } catch (e) {
        runToast("Errore download file", "top-center", "danger");
      }
    };
    const goToHome = function () {
      router.push("/");
    };

    const goToStorico = function () {
      router.push("/storico-diagnosi");
    };

    const goToStoricoCar = function () {
      router.push("/car");
    };

    const goToStoricoPagamenti = function () {
      router.push("/assistenza-senza-sorprese/pagamenti");
    };

    const goToChangeIban = function () {
      router.push("/assistenza-senza-sorprese/cambia-pagamento");
    };

    const goToAgente = function () {
      router.push("/agente");
    };

    const goToStoricoRichieste = function () {
      router.push("/assistenza-senza-sorprese/lista-richieste");
    };

    const goToOpenAssistenza = function () {
      router.push("/assistenza-senza-sorprese/open-case");
    };

    const goToPortalePagamenti = async function () {
      let resp = await apiCall({
        url: "/private/pagamenti/portale",
        data: {},
        method: "GET",
      });
      if (!resp.err) {
        window.open(resp.url, "_blank");
      }
    };

    return {
      assistenza,
      isLoading,
      numOpen,
      goToStorico,
      goToStoricoCar,
      goToStoricoPagamenti,
      openDiagnosi,
      formatDate,
      toCurrency,
      goToHome,
      openContratto,
      goToChangeIban,
      goToAgente,
      goToStoricoRichieste,
      goToOpenAssistenza,
      goToPortalePagamenti,
    };
  },
};
</script>
